@import "_progress-bar.css";
@import "_recharts.css";

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background-color: black;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

ul {
  list-style: inside;
  list-style-type: none;
}

input,
textarea,
button,
select {
  appearance: none;
  border-radius: 0;
  outline: 0;

  &[type="checkbox"] {
    appearance: checkbox;
  }
}

html,
body,
[id="__next"] {
  height: 100%;
  width: 100%;
}

[id="__next"] {
  height: auto;
  min-height: 100%;
  font-size: 1.8em;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  font-family:
    "Public Sans",
    -apple-system,
    BlinkMacSystemFont,
    "San Francisco",
    "Segoe UI",
    "Helvetica Neue",
    "Lucida Grande",
    sans-serif;
  font-display: swap;
  overflow: hidden;
}

h1 {
  font-size: 1.56em;
  font-weight: 600;
}

h1,
h2,
h3 {
  line-height: 1.4;
  font-weight: 600;
}

span::selection,
p::selection,
a::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection {
  background: rgb(98 54 255 / 99%) !important;
  color: white !important;
}

:root {
  --navigation-height: 88px;
}
